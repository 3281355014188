//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import PrivateChatsFilters from './PrivateChatsFilters.vue';
import Pagination from '~/components/Pagination.vue';
import Table from '~/components/ui/Table.vue';
import User from '~/components/User.vue';
import tableConfig from './privateChatsTableConfig';
import utils from '~/utils';

export default {
  components: {
    PrivateChatsFilters,
    Pagination,
    Table,
    User,
  },

  data() {
    return {
      selection: [],
      currentChatId: 0,
      loader: false,
      chatLoader: false,
      tableConfig,
      utils,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),
    ...mapState('userPrivateChats', [
      'privateChats',
      'page',
      'pages',
      'sort',
      'currentChat',
    ]),
    ...mapGetters('login', ['getAdminById']),
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'confirmAction',
    ]),
    ...mapActions('userPrivateChats', {
      getPrivateChatsAction: 'getPrivateChats',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
      deleteChatAction: 'deleteChat',
      loadCurrentChatAction: 'loadCurrentChat',
      deleteChatMessageAction: 'deleteChatMessage',
      editChatMessageAction: 'editChatMessage',
    }),

    async getPrivateChats(data = {}) {
      this.loader = true;
      await this.getPrivateChatsAction(data);
      this.loader = false;
      const { privateChats } = this;
      if (privateChats && privateChats.length) {
        const { id } = privateChats[0];
        this.setCurrentChat({ id });
      }
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
      }

      this.getPrivateChats();
    },

    applySort(data) {
      this.setSortAction(data);
      this.getPrivateChats();
    },

    setPage(value) {
      this.setPageAction(value);
      const extendedRequestData = {
        getTotal: false,
      };
      this.getPrivateChats(extendedRequestData);
    },

    deleteChat(id) {
      this.confirmAction({
        text: 'Are you sure you want to delete chat?',
        callback: async () => {
          this.loader = true;
          this.setGeneralProgress(true);
          await this.deleteChatAction(id);
          await this.getPrivateChatsAction();
          this.loader = false;
          this.setGeneralProgress(false);
          this.setSuccessNotification('Chat deleted');
        },
      });
    },

    setCurrentChat({ id }) {
      this.currentChatId = id;
      this.loadCurrentChat();
    },

    async loadCurrentChat() {
      const { currentChatId } = this;
      this.chatLoader = true;
      this.setGeneralProgress(true);
      await this.loadCurrentChatAction(currentChatId);
      this.chatLoader = false;
      this.setGeneralProgress(false);
    },

    deleteMessage(id) {
      const { currentChatId } = this;
      this.confirmAction({
        text: 'Are you sure you want to delete message?',
        callback: async () => {
          this.setGeneralProgress(true);
          await this.deleteChatMessageAction(id);
          await this.loadCurrentChatAction(currentChatId);
          this.setGeneralProgress(false);
          this.setSuccessNotification('Message deleted');
        },
      });
    },

    editMessage(message) {
      const { currentChatId } = this;
      const { id, text } = message;
      this.confirmAction({
        text: 'Edit message',
        hasPrompt: true,
        promptRequired: true,
        promptLabel: 'Message',
        promptValue: text,
        callback: async ({ prompt }) => {
          this.setGeneralProgress(true);
          await this.editChatMessageAction({
            messageId: id,
            message: prompt,
          });
          await this.loadCurrentChatAction(currentChatId);
          await this.getPrivateChatsAction({});
          this.setGeneralProgress(false);
          this.setSuccessNotification('Message edited');
        },
      });
    },

    isMessageDeletedBy(message) {
      const {
        deletedBySender,
        deletedByReceiver,
        deleted,
        adminId,
      } = message;

      if (deletedBySender) return 'sender';
      if (deletedByReceiver) return 'receiver';
      if (deleted) {
        const admin = this.getAdminById(adminId);
        if (admin) {
          return `${this.$t('admin')}: ${admin.email}`;
        }
        return this.$t('admin');
      }
      return '';
    },

    isMessageEditedBy(message) {
      const { adminId } = message;
      const admin = this.getAdminById(adminId);
      if (admin) {
        return `${this.$t('admin')}: ${admin.email}`;
      }
      return this.$t('admin');
    },

    isMessageDeleted(message) {
      const {
        deletedBySender,
        deletedByReceiver,
        deleted,
      } = message;

      return deletedBySender || deletedByReceiver || deleted;
    },
  },
};
