export default [
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Date',
      sort: 'id',
    },
    cell: {
      type: 'slot',
      name: 'date',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Id',
    },
    cell: {
      type: 'text',
      value: (e) => e.id,
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Username',
    },
    cell: {
      type: 'slot',
      name: 'username',
    },
  },
  {
    width: 45,
    header: {
      type: 'text',
      caption: 'Message',
    },
    cell: {
      type: 'slot',
      name: 'message',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Action',
    },
    cell: {
      type: 'slot',
      name: 'action',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Admin',
    },
    cell: {
      type: 'slot',
      name: 'admin',
    },
  },
  {
    width: 5,
    header: {
      type: 'text',
      caption: 'Spam',
    },
    cell: {
      type: 'slot',
      name: 'spam',
    },
  },
];
